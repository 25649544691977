var Ajax = require('@/service/ajax.js'); //ajax
var EncryptFactory = require('@/js/encrypt.js');
var http = new Ajax.AjaxFactory();
var encryptFactory = new EncryptFactory();

function handelBizCode(option) {
	if (typeof option === 'string' && option.substring(0, 1) === '9') {
		return true;
	} else {
		return false;
	}
}

/**
 * @description service factory function
 */
function ServiceFactory(options) {
	this.requestOptions = {
		authClientId: options.authClientId && options.authClientId,
		otp: '',
		phoneNo: '',
		pin: '',
		hashCode: options.hashCode && options.hashCode,
		redirectUri: options.redirectUri && options.redirectUri
	};
	this.redirectUri = '';
	this.responseData = {};
}

/**
 * @description verify  phoen number(step 1 service)
 */
ServiceFactory.prototype.verifyAccount = function (phone, target) {
	var verifyAccountOption = $.extend({}, this.requestOptions, {
		phoneNo: phone
	});
	var reqURL = '/portal/verifyAccount';
	http.post(
		reqURL,
		verifyAccountOption,
		function (res) {
			removeDisabled(target);
			// clear disable
			if (res && +res.rtnCode !== 10000 && !handelBizCode(res.rtnCode)) {
				// handel error
				this.requestOptions.phoneNo = '';
				this.handelError({
					res: res,
					eleId: '#J_account_page',
					target: target
				});
			} else if (res && res.rtnCode && +res.rtnCode === 10000) {
				this.requestOptions.phoneNo = phone;

				step.goNextStep();
			} else {
				this.requestOptions.phoneNo = '';
				// go to error page
				var msg = res.showInfo || '';
				location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','',msg) +'&hashCode=' + (getQueryString('hashCode') || '');
			}
		}.bind(this),
		function (err) {
			$(target).find('.confirm').removeAttr('disabled').removeClass('disabled');
			location.href = './error.html';
		}.bind(this)
	);
};

/**
 * @description verify opt (step 2)
 */
ServiceFactory.prototype.verifyOtp = function (otp, target) {
	var reqURL = '/portal/verifyOtp';
	var verifyOtpOptions = $.extend({}, this.requestOptions, { otp: otp });
	http.post(
		reqURL,
		verifyOtpOptions,
		function (res) {
			removeDisabled(target);
			if (res && +res.rtnCode !== 10000 && !handelBizCode(res.rtnCode)) {
				// handel error
				if (res.rtnCode === '10006') {
					$('#recend-info').addClass('fn-hide');
				}
				// $('#J_otp_page').find('.error-info').removeClass('fn-hide').html(res.rtnMsg)
				this.handelError({ res: res, eleId: '#J_otp_page', target: target });
			} else if (res && res.rtnCode && +res.rtnCode === 10000) {
				step.goNextStep();
			} else {
				// go to error page
				var msg = res.showInfo || '';
				location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','',msg) + '&hashCode=' + (getQueryString('hashCode') || '');
			}
		}.bind(this),
		function () {
			removeDisabled(target);
			location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
		}
	);
};

/**
 * @description receive otp(phone code)
 * @param (string) target phone number
 */
ServiceFactory.prototype.resendOTPService = function (target) {
	var resendOtpOPtions = $.extend({}, this.requestOptions, { otp: '' });
	var reqURL = '/portal/resendOtp';
	http.post(
		reqURL,
		resendOtpOPtions,
		function (res) {
			$(target).find('.confirm').removeAttr('disabled').removeClass('disabled');
			if (res && +res.rtnCode !== 10000 && !handelBizCode(res.rtnCode)) {
				// handel error
				if (res.rtnCode === '10006') {
					$('#recend-info').addClass('fn-hide');
				}
				if (res.rtnCode == 10005) {
					$('#recend-info').addClass('fn-hide');
				}
				this.handelError({ res: res, target: target, eleId: '#J_otp_page' });
			} else if (res && res.rtnCode && +res.rtnCode === 10000) {
				$('.resend-control').addClass('fn-hide');
				$('#J_resend_timer').removeClass('fn-hide');
				window.start()
				// clearInterval(optTimer)
				// step.goNextStep()
			} else {
				// go to error page
				$(target).removeClass('disabled').removeAttr('disabled');
				var msg = res.showInfo || '';
				location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','',msg) + '&hashCode=' + (getQueryString('hashCode') || '');
			}
			// closePreLoading()
		}.bind(this),
		function () {
			location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
		}
	);
};

/**
 * @param {string} phone get key info(step 4)
 */
ServiceFactory.prototype.getPINKeysService = function (PIN, target) {
	var getPINKeyOptions = $.extend({}, this.requestOptions);
	var reqURL = '/portal/getKeys';
	// post request
	http.post(
		reqURL,
		getPINKeyOptions,
		function (res) {
			if (res && +res.rtnCode !== 10000 && !handelBizCode(res.rtnCode)) {
				removeDisabled(target);
				this.requestOptions.pin = '';
				this.handelError({ res: res, eleId: '#J_pin_page', target: target });
			} else if (res && +res.rtnCode === 10000) {
				// success
				var resData = res.data;
				this.responseData.SALT = resData.SALT || '';
				this.responseData.RSAPublicKey = resData.RSAPublicKey || '';
				this.responseData.OFFSET = resData.OFFSET || '';
				this.responseData.AESKey = resData.AESKey || '';
				// handel pin
				var pinCode = encryptFactory.encryptedPINCode(PIN, this.responseData);
				if (!pinCode) {
					$('#J_pin_page').find('.error-info').html('invalid PIN');
					$('#J_pin_page').find('input').val = '';
					return false;
				}
				this.requestOptions.pin = pinCode;
				this.verifyPINService(target);
			} else {
				removeDisabled(target);
				// go to error page
				var msg = res.showInfo || '';
				location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','',msg) + '&hashCode=' + (getQueryString('hashCode') || '');
			}
		}.bind(this),
		function () {
			removeDisabled(target);
			this.requestOptions.pin = '';
			location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
		}.bind(this)
	);
};

/**
 * @description service of verify PIN code (step 5)
 */
ServiceFactory.prototype.verifyPINService = function (target) {
	var verifyPINOptions = $.extend({}, this.requestOptions);
	var reqUrl = '/portal/verifyPin';
	http.post(
		reqUrl,
		verifyPINOptions,
		function (res) {
			removeDisabled(target);
			this.requestOptions.pin = '';
			$('#J_pin_page').find('input').val = '';
			if (res && +res.rtnCode !== 10000 && !handelBizCode(res.rtnCode)) {
				// handel error
				this.handelError({ res: res, eleId: '#J_pin_page', target: target });
			} else if (res && res.rtnCode && +res.rtnCode === 10000) {
				var redirectUri = res.data;
				this.redirectUri = redirectUri;
				step.goNextStep();
			} else {
				// go to error page
				var msg = res.showInfo || '';
				location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','',msg) + '&hashCode=' + (getQueryString('hashCode') || '');
			}
		}.bind(this),
		function (err) {
			this.requestOptions.pin = '';
			removeDisabled(target);
			location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
		}.bind(this)
	);
};

ServiceFactory.prototype.verifyPINService = function (target) {
	var verifyPINOptions = $.extend({}, this.requestOptions);
	var reqUrl = '/portal/verifyPin';
	http.post(
		reqUrl,
		verifyPINOptions,
		function (res) {
			removeDisabled(target);
			this.requestOptions.pin = '';
			$('#J_pin_page').find('input').val = '';
			if (res && +res.rtnCode !== 10000 && !handelBizCode(res.rtnCode)) {
				// handel error
				this.handelError({
					res: res,
					eleId: '#J_pin_page',
					target: target
				});
			} else if (res && res.rtnCode && +res.rtnCode === 10000) {
				var redirectUri = res.data;
				this.redirectUri = redirectUri;
				step.goNextStep();
			} else {
				// go to error page
				var msg = res.showInfo || '';
				location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','',msg) + '&hashCode=' + (getQueryString('hashCode') || '');
			}
		}.bind(this),
		function (err) {
			this.requestOptions.pin = '';
			removeDisabled(target);
			location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
		}.bind(this)
	);
};



/**
 * @description system config
 */
ServiceFactory.prototype.getConfigInfo = function (callback) {
	var reqUrl = '/sys/cfg?t=' + (new Date().getTime() + '');
	http.post(
		reqUrl,
		{
			hashCode: this.requestOptions.hashCode,
			terms: true
		},
		function (res) {

			if (res && res.rtnCode && Number(res.rtnCode) === 10000) {
				var resData = res.data;
				var systemConfig = resData;
				$('.agreement-content').append(systemConfig.content);
				$('#J_button_group .confirm').removeAttr('style');
				//enable "I agree" button when load T&C successfully
				$('#J_button_group .confirm').bind('click', function () {
					var hashCode = getQueryString('hashCode') || '';
					var redirectUri = getQueryString('redirectUri') || '';
					var authClientId = getQueryString('authClientId') || '';
					var nextPage =
						'./account.html?hashCode=' +
						hashCode +
						'&redirectUri=' +
						redirectUri +
						'&authClientId=' +
						authClientId;
					handelLogo(nextPage, hashCode);
				});
				try {
					sessionStorage.setItem('systemConfig', JSON.stringify(systemConfig));
					localStorage.setItem('systemConfig', JSON.stringify(systemConfig));
					if (callback && typeof callback === 'function') {
						callback()
					}
				} catch (error) {
					try {
            localStorage.clear();
            sessionStorage.clear();
						setTimeout(function() {
							console.log('before set item');
							localStorage.setItem('systemConfig', JSON.stringify(systemConfig));
							sessionStorage.setItem('systemConfig',JSON.stringify(systemConfig));
							console.log('after set item');
							if (callback && typeof callback === 'function') {
								callback()
							}
						}, 200)
					} catch (error) {
						console.log('try catch error in setItem', error);
						location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','','Unfortunately your request could not be processed. Please try again.');
          }
				}
				enableButtonIfNeeded();
			} else if (res && res.rtnCode && +res.rtnCode !== 10000) {
				console.log('get config else if');

				var msg = '';
				if (res.showInfo) {
					msg = res.showInfo;
				}
				var _hashCode_ = getQueryString('hashCode') || '';
				sessionStorage.setItem('_hashCode_', JSON.stringify(_hashCode_));
				location.href = './error.html?errorMessage=' + encryptFactory.encryptByAes('','',msg) + '&hashCode=' + (getQueryString('hashCode') || '');
			} else {
				console.log('get config else');
				location.href = './error.html?errorMessage=' +encryptFactory.encryptByAes('','','Unfortunately your request could not be processed. Please try again.')  + '&hashCode=' + (getQueryString('hashCode') || '');
			}
		},
		function (err) {
			console.error(err);
			location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
		}
	);
};

function enableButtonIfNeeded() {
	const scrollAble = $("#terms")

	// if T&C content not scrollable
	if (scrollAble[0].scrollHeight <= scrollAble.outerHeight()){
		$("#agree").removeClass('disabled');
	}
}

/**
 * @description click agreen button (upload service)
 */
function handelLogo(redirectUri, hashCode) {
	var reqUrl = '/biLog/upload';
	http.post(
		reqUrl,
		{
			action: 'AGREE_BINDING',
			screen: 'PAGE_T&C',
			eventType: 'AGREE_BINDING',
			hashCode: hashCode
		},
		function (res) {
			if (res && res.rtnCode && +res.rtnCode === 10000) {
				location.href = decodeURIComponent(redirectUri);
			} else {
				location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
			}
		}
	);
}
/**
 * @description handel error
 */
ServiceFactory.prototype.handelError = function (options) {
	// show error message
	var msg = options.res.showInfo || '';
	if (msg) {
		$(options.eleId).find('.error-info').removeClass('fn-hide').html(msg);
		if (options.res.rtnCode == 10018) {
			$('#J_otp_page').find('input').attr('disabled', 'disabled');
			$('#recend-info').addClass('fn-hide');
			$("button[name='string_control_confirm']").attr('disabled', 'disabled');
		}
		if (options.res.rtnCode === '10009') {
			$("button[name='string_control_confirm']").attr('disabled', 'disabled');
			$('#recend-info').addClass('fn-hide');
			$('#J_otp_page').find('input').attr('disabled', 'disabled');
		}
		if (options.res.rtnCode === '10006') {
			$('#J_pin_page').find('input').attr('disabled', 'disabled');
			$("button[name='string_control_confirm']").attr('disabled', 'disabled');
		}
		if (options.res.rtnCode == 10005) {
			$("button[name='string_control_confirm']").attr('disabled', 'disabled');
			$('#J_account_page').find('input').attr('disabled', 'disabled');
		}
		return;
	}
	options.target.find('input').val('');
	location.href = './error.html' + '?hashCode=' + (getQueryString('hashCode') || '');
};

/**
 * @description remove disabled attribute
 * @param {object} target element object
 */
function removeDisabled(target) {
	$(target).find('.confirm').removeClass('disabled').removeAttr('disabled');
}
module.exports = ServiceFactory;
